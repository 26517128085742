/*Common CSS Start*/
@media (min-width: 768px) {
  html {
    font-size: 74%;
  }
}
@media (min-width: 1200px) {
  html {
    font-size: 68%;
  }
}
@media (min-width: 1300px) {
  html {
    font-size: 74%;
  }
}
@media (min-width: 1400px) {
  html {
    font-size: 76%;
  }
}
@media (min-width: 1600px) {
  html {
    font-size: 90%;
  }
}
@media (min-width: 1800px) {
  html {
    font-size: 100%;
  }
}

@media (max-width: 320px) {
  html {
    font-size: 90%;
  }
}

.customScroll::-webkit-scrollbar {
  width: 0.375rem;
  background-color: #d9d9d9;
  border-radius: 1rem;
}

.customScroll::-webkit-scrollbar-track {
  border-radius: 1rem;
  background-color: #d9d9d9;
}
.customScroll::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: #707070;
}

.fs-18 {
  font-size: 1.125rem;
}

.fs-11 {
  font-size: 0.688rem;
}

.fs-12 {
  font-size: 0.75rem;
}

.fs-14 {
  font-size: 0.875rem;
}

.fs-10 {
  font-size: 0.625rem;
}

.text-truncate-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
}

.text-truncate-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: pre-wrap;
}

.text-truncate-6 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  white-space: pre-wrap;
}
