/* .formik-input .formik-icon {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}

.formik-input .formik-icon svg {
  height: 1rem;
}
 */

/* --- Template Common ---*/
.AuthIndex {
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  background-color: #eceef0;
}

.AuthIndex input:-webkit-autofill,
.AuthIndex input:-webkit-autofill:hover,
.AuthIndex input:-webkit-autofill:focus,
.AuthIndex input:-webkit-autofill:active {
  -webkit-box-shadow: none;
}
/* --- --- */

/* --- View Common --- */
.AuthIndex .SignIn,
.AuthIndex .SignUp,
.AuthIndex .ChangePassword,
.AuthIndex .ResetPassword {
  width: 420px;
  max-width: 100%;
}

.AuthIndex .AuthLogo {
  cursor: pointer !important;
}

@media (max-width: 1399.98px) {
  .AuthIndex .SignIn,
  .AuthIndex .SignUp,
  .AuthIndex .ChangePassword,
  .AuthIndex .ResetPassword {
    width: 320px;
  }
}
@media (max-width: 767.98px) {
  .AuthIndex .SignIn,
  .AuthIndex .SignUp,
  .AuthIndex .ChangePassword,
  .AuthIndex .ResetPassword {
    width: 420px;
  }
}
@media (max-width: 575.98px) {
  .AuthIndex .SignIn,
  .AuthIndex .SignUp,
  .AuthIndex .ChangePassword,
  .AuthIndex .ResetPassword {
    width: 100%;
  }
}
.AuthCarousel .slick-list .slick-slide > div,
.AuthCarousel .ant-carousel,
.AuthCarousel,
.AuthCarousel .AuthCarouselItems,
.AuthCarousel .slick-list,
.AuthCarousel .slick-track {
  height: 100vh;
}

.AuthCarousel .AuthCarouselItems img {
  object-fit: cover;
}

.AuthCarousel .AuthCarouselItems .AuthCarouselInfo {
  position: absolute;
  bottom: 5rem;
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
  left: 5%;
  z-index: 999;
}

.AuthCarousel .slick-dots {
  bottom: 3rem;
}

.AuthCarousel .slick-dots li button:before {
  display: none !important;
}

.AuthCarouelSlider .AuthCarouselItems {
  position: relative;
}

.AuthCarouelSlider .AuthCarouselItems:before {
  content: "";
  background: linear-gradient(358deg, #090909 0%, rgba(11, 22, 29, 0.697917) 21.88%, rgba(14, 52, 75, 0) 51.56%);
  height: 100vh;
  position: absolute !important;
  width: 100%;
  left: 0;
  z-index: 1;
  opacity: 0.9;
}

.AuthIndex svg [fill="var(--primary)"] {
  fill: #acb0b4;
}

.AuthIndex #container:has(input:focus) svg [fill="var(--primary)"] {
  fill: var(--primary);
}

/* --- --- */
.AuthIndex .FormHeaderText {
  font-size: 32px;
  line-height: 42px;
  color: var(--dark);
  margin-bottom: 0px;
}

.AuthIndex .FormHeaderSubText {
  /* font-size: 13px; */
  margin-bottom: 30px;
}

.AuthIndex .FormInput {
  width: 100%;
  height: 100%;
  font-size: 15px;
  line-height: 20px;
  border-radius: 12px;
  padding-right: 5px;
}

.AuthIndex .FormCheckConfirm label {
  font-size: 12px;
}

.AuthIndex .FormCheckConfirm input[type="checkbox"] {
  vertical-align: top;
  accent-color: var(--primary);
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.AuthIndex .BackLink {
  margin-top: 5px;
  font-family: "ff_rg";
  font-size: 15px;
  line-height: 20px;
  color: var(--dark);
}
/** --- Input Box --- */
.InputBox {
  margin-top: 15px;
}
.InputBox label {
  font-size: 12px;
  color: var(--dark);
}
.InputBox #container,
.InputBox input,
.InputBox textarea {
  width: 100%;
  height: 50px;
  border-radius: 12px;
  margin-top: 0px;
}

.InputBox #iconContainer {
  width: 20px;
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 10px;
}
/** --- --- */

/** --- Button --- */
/* .AuthIndex .Button {
  font-size: 14px;
  width: 100%;
  height: 54px;
  border-radius: 12px;
  margin-bottom: 15px;
}
.AuthIndex .Button.TopMost {
  margin-top: 25px;
} */
/** --- --- */

/* --- Auth Common All --- */
/* .AuthIndex .FormHeaderText,
.AuthIndex .Button {
  font-family: "ff_bd";
} */
.AuthIndex .FormHeaderSubText,
.AuthIndex .FormCheckConfirm label,
.InputBox label {
  font-family: "ff_rg";
  line-height: 17px;
}

.AuthIndex .InputBox #container {
  background-color: var(--primary-opacity);
}

.AuthIndex .InputBox input {
  background-color: transparent;
}

.AuthIndex .CustomerInput input:focus {
  box-shadow: none;
  border: 1px solid transparent;
}

.AuthIndex .ant-carousel {
  height: 100%;
}
/* --- --- */

/* Input Focus Change SVG Color */
.InputBox:has(input:focus) svg [fill="var(--light-md)"] {
  fill: var(--primary);
}

.orAfterBeforeLine::before,
.orAfterBeforeLine::after {
  content: "";
  width: 75px;
  height: 1.5px;
  display: block;
  background-color: #df2caa;
  position: absolute;
  top: calc(50% - 2px);
}

.orAfterBeforeLine::before {
  right: calc(50% + 20px);
}

.orAfterBeforeLine::after {
  left: calc(50% + 20px);
}

/* .AuthIndex .ResetPassword,
.AuthIndex .ChangePassword,
.AuthIndex .SignIn {
  padding-top: 70px;
} */

body.current-password .mainMenu,
body.current-password .toggleButton,
body.current-password .AuthCarouelSlider {
  display: none !important;
}

body.current-password .AuthCarouelFormDetails {
  width: 100%;
}

body.current-password .AuthIndex {
  padding-top: 70px;
}

body.current-password .AuthIndex .ChangePassword {
  padding-top: 0px;
}

.password_show_hide {
  top: 0;
  right: 1rem;
  height: 100%;
  line-height: 2.75rem;
  width: 1.25rem;
  text-align: center;
}
.password_show_hide svg {
  width: 1.125rem;
  height: 1.125rem;
}

@media screen and (max-width: 768px) {
  .AuthCarousel .slick-list .slick-slide > div,
  .AuthCarousel .ant-carousel,
  .AuthCarousel,
  .AuthCarousel .AuthCarouselItems,
  .AuthCarousel .slick-list,
  .AuthCarousel .slick-track {
    height: calc(100%);
  }

  .AuthIndex .SignIn,
  .AuthIndex .ChangePassword,
  .AuthIndex .ResetPassword {
    padding-top: 0;
  }

  .AuthIndex .SignUp {
    padding-top: 70px;
  }
}

@media screen and (max-width: 991px) {
  .AuthIndex .SignIn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (min-width: 992px) {
  .AuthIndex .AuthCarouelFormDetails {
    position: absolute;
    right: 0;
    overflow: scroll;
    top: 0;
    height: 100vh;
  }

  .AuthIndex .AuthCarouelFormDetails::-webkit-scrollbar {
    display: none;
  }

  /* .AuthIndex .ResetPassword, */
  /* .AuthIndex .ChangePassword,
  .AuthIndex .SignIn,
  .AuthIndex .SignUp {
    padding-top: 80px;
  } */

  body.current-password .AuthIndex .ChangePassword {
    padding-top: 70px;
  }
}

@media screen and (min-width: 1024px) {
  .AuthCarouelSlider:before {
    bottom: 0px;
  }

  /* .AuthIndex .SignIn {
    padding-top: 90px;
  } */
}

@media screen and (min-width: 320px) and (max-width: 576px) {
  .AuthCarouelSlider,
  .AuthCarouelSlider .ant-carousel {
    height: 50vh;
  }

  .AuthCarouelSlider:before {
    bottom: 0px;
  }

  .AuthCarouelSlider .AuthCarouselItems:before {
    height: 50vh;
  }
}

@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .AuthCarouelSlider,
  .AuthCarouelSlider .ant-carousel {
    height: 75vh;
  }

  .AuthCarouelSlider:before {
    bottom: 0px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .AuthCarouelSlider .ant-carousel {
    height: calc(100vh - 75px);
  }

  .AuthCarouelSlider:before {
    bottom: 0px;
  }

  .AuthIndex .ResetPassword,
  .AuthIndex .ChangePassword {
    padding-top: 70px;
  }
}
