html,
body,
#root,
.ant-app {
	font-family: "Plus Jakarta Sans", sans-serif;
}

* {
	border: none;
	outline: none;
}
a {
	text-decoration: none !important;
	color: inherit !important;
}

:root {
	--primary: #6c9835;
	--primary-bg: #ebf0f6;
	--primary-opacity: rgb(14 52 75 / 10%);
	--light-md: #5e5555;
}

.primary-bg {
	background-color: var(--primary-bg) !important;
}

.ff {
	font-family: "Plus Jakarta Sans", sans-serif !important;
}

.ff_sf {
	font-family: "Sofia Sans", sans-serif !important;
	font-optical-sizing: auto !important;
	font-weight: 400 !important;
	font-style: normal !important;
}

.ff_lt {
	font-family: "Plus Jakarta Sans", sans-serif;
	font-weight: 300 !important;
	font-style: normal !important;
}

.ff_rg {
	font-family: "Plus Jakarta Sans", sans-serif;
	font-weight: 400 !important;
	font-style: normal !important;
}

.ff_md {
	font-family: "Plus Jakarta Sans", sans-serif;
	font-weight: 500 !important;
	font-style: normal !important;
}

.ff_bd {
	font-family: "Plus Jakarta Sans", sans-serif;
	font-weight: 700 !important;
	font-style: normal !important;
}

/* font size */

.f12 {
	font-size: 0.75rem !important;
}

.f14 {
	font-size: 0.875rem !important;
}

.F12 {
	font-size: 12px !important;
}

.F14 {
	font-size: 14px !important;
}

.F16 {
	font-size: 16px !important;
}

.f16 {
	font-size: 1rem !important;
}

.f18 {
	font-size: 1.125rem !important;
}

.f20 {
	font-size: 1.25rem !important;
}

.f24 {
	font-size: 1.5rem !important;
}
.f26 {
	font-size: 1.625rem !important;
}

.f28 {
	font-size: 1.75rem !important;
}

.f30 {
	font-size: 1.875rem !important;
}

.f32 {
	font-size: 2rem !important;
}

.f35 {
	font-size: 2.188rem !important;
}

.c-primary {
	background-color: var(--primary) !important;
}

.txt-white {
	color: white !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type="number"] {
	-moz-appearance: textfield;
}
