/* Common CSS Start */
@media (min-width: 768px) {
	html {
		font-size: 74%;
	}
}
@media (min-width: 1200px) {
	html {
		font-size: 68%;
	}
}
@media (min-width: 1300px) {
	html {
		font-size: 74%;
	}
}
@media (min-width: 1400px) {
	html {
		font-size: 76%;
	}
}
@media (min-width: 1600px) {
	html {
		font-size: 90%;
	}
}
@media (min-width: 1800px) {
	html {
		font-size: 100%;
	}
}

body {
	overflow-x: hidden;
}

/* Custom Cursor Start */
.custom-cursor {
	display: none;
}
@media (min-width: 992px) {
	.custom-cursor {
		display: block;
	}
}
.custom-cursor div {
	position: absolute;
	top: 18.75rem;
	left: 18.75rem;
	width: 2rem;
	height: 2rem;
	border: 1px solid #ff5e3e;
	border-radius: 50%;
	z-index: 9999;
	pointer-events: none;
	transform: translate(-50%, -50%);
}
.custom-cursor div:nth-child(2) {
	position: absolute;
	top: 18.75rem;
	left: 18.75rem;
	width: 0.75rem;
	height: 0.75rem;
	background: linear-gradient(90deg, #ff5e3e, #df2caa);
	border-radius: 50%;
	z-index: 9999;
	pointer-events: none;
	transform: translate(-50%, -50%);
}
.custom-cursor div span {
	position: absolute;
	top: 2.75rem;
	left: 3.4375rem;
	width: 18.75rem;
	font-size: 0.75rem;
	font-weight: 500;
	line-height: 1;
	color: hsl(var(--white));
	opacity: 0;
	transition: opacity 0.5s;
	z-index: 1;
}
.custom-cursor div span.visible {
	opacity: 1;
}
.custom-cursor__img {
	position: absolute;
	top: 1.25rem;
	left: 2rem;
	opacity: 0;
	transition: opacity 0.5s;
}
.custom-cursor__img.visible {
	opacity: 1;
}
/* Custom Cursor End */

.animated-border-btn {
	position: relative;
	transition: all 0.3s ease;
	display: inline-flex;
	justify-content: center;
	text-decoration: none;
	overflow: hidden;
	--border-width: 0.125rem;
	padding: var(--border-width);
	border-radius: 0.313rem;
	-webkit-border-radius: 0.313rem;
	-moz-border-radius: 0.313rem;
	-ms-border-radius: 0.313rem;
	-o-border-radius: 0.313rem;
}

.animated-border-btn::after {
	content: "";
	position: absolute;
	left: -50%;
	top: -50%;
	width: 200%;
	height: 200%;
	background-color: transparent;
	background-repeat: no-repeat;
	background-size: 50% 50%, 50% 50%;
	background-position: 0 0, 100% 0, 100% 100%, 0 100%;
	background-image: radial-gradient(circle, #ffffff 0, rgba(252, 70, 107, 0) 71%), linear-gradient(transparent, transparent), linear-gradient(transparent, transparent),
		linear-gradient(transparent, transparent);
	animation: borderRotate 4s linear infinite;
	z-index: -1;
	pointer-events: none;
}

@keyframes borderRotate {
	100% {
		transform: rotate(1turn);
	}
}
.page-btn-grad {
	/* background: linear-gradient(90deg, #ff5e3e, #df2caa); */
	background: linear-gradient(90deg, #df2caa, #ff5e3e, #df2caa);
	background-size: 200%;
	transition: all 0.4s ease-in-out;
	background-position: left;
	border-color: transparent !important;
	color: #ffffff !important;
}
.page-btn-grad:hover {
	background-position: right;
	transition: all 0.4s ease-in-out;
}
.btn-lg.page-btn-bg {
	text-transform: uppercase;
	--bs-btn-font-size: 1.5rem;
	--bs-btn-padding-x: 3rem;
	--bs-btn-padding-y: 1rem;
	border-radius: 0.188rem;
	-webkit-border-radius: 0.188rem;
	-moz-border-radius: 0.188rem;
	-ms-border-radius: 0.188rem;
	-o-border-radius: 0.188rem;
}
.section-title {
	margin-bottom: 5rem;
}
.section-title h2 {
	text-transform: uppercase;
	color: #fff;
	font-size: 3.75rem;
	margin-bottom: 0;
}
.section-title h2 .text-grad {
	background: -webkit-linear-gradient(left, #f85d94, #fe135e);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.section-title p {
	color: #fe135e;
	font-size: 1.875rem;
}
.top-line {
	width: 0.188rem;
	height: 8.75rem;
	position: relative;
	margin-bottom: 2rem;
	background: linear-gradient(90deg, #df2caa, #ff5e3e, #df2caa);
	background-image: -moz-linear-gradient(-92deg, rgb(254, 19, 94) 0%, rgb(248, 93, 148) 100%);
	background-image: -webkit-linear-gradient(-92deg, rgb(254, 19, 94) 0%, rgb(248, 93, 148) 100%);
	background-image: -ms-linear-gradient(-92deg, rgb(254, 19, 94) 0%, rgb(248, 93, 148) 100%);
}
.top-line::after {
	content: "";
	width: 1rem;
	height: 1rem;
	background-color: #f85d94;
	position: absolute;
	border: 0.188rem solid #fe135e;
	border-radius: 100%;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	-ms-border-radius: 100%;
	-o-border-radius: 100%;
	left: 50%;
	top: -1rem;
	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	transition: top 2s;
	-webkit-transition: top 2s;
	-moz-transition: top 2s;
	-ms-transition: top 2s;
	-o-transition: top 2s;
}
.top-line.active::after {
	top: calc(100% - 1rem); /* Position at the bottom of the vertical line */
}
.bg-overlay-common {
	position: relative;
}
.bg-overlay-common::before {
	content: "";
	background-image: url("../../Assets/Images/page-bg.webp");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: bottom center;
	position: absolute;
	width: 100%;
	height: 100%;
	bottom: 0;
	left: 0;
	/* z-index: -1; */
	opacity: 0.13;
}
.bg-overlay-common .container {
	position: relative;
	z-index: 2;
}

@media (max-width: 767.98px) {
	.top-line {
		height: 5rem;
	}
	.section-title {
		margin-bottom: 2rem;
	}
	.section-title h2 {
		font-size: 1.875rem;
	}
	.section-title p {
		font-size: 1rem;
	}
	.btn-lg.page-btn-bg {
		--bs-btn-font-size: 1rem;
		--bs-btn-padding-x: 2rem;
		--bs-btn-padding-y: 0.75rem;
	}
}
/* Common CSS End */

/* Header CSS Start */
.header {
	transition: all 0.3s ease;
}
.header.header-fixed-top::after {
	content: "";
	position: absolute;
	inset: 0;
	z-index: -1;
	background: rgba(0, 0, 0, 0.5);
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
}
.navbar-brand img {
	width: 18.688rem;
	height: 2.625rem;
}
.navbar-header {
	isolation: isolate;
}
.navbar-menu {
	display: flex;
	position: absolute;
	inset-inline: 0;
	inset-block-start: calc(100% - 0.5rem);
	background: rgba(0, 0, 0, 1);
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	transition: all 0.3s ease;
	z-index: -1;
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
}
@media (min-width: 992px) {
	.navbar-menu {
		background-color: transparent;
		position: relative;
		z-index: 1;
		visibility: visible;
		opacity: 1;
		pointer-events: unset;
		box-shadow: none;
	}
	.navbar-menu ul + ul li + li {
		position: relative;
		isolation: isolate;
	}
	.navbar-menu ul + ul li + li::before {
		content: "";
		width: 1px;
		height: 1.5rem;
		background-color: hsl(var(--outline) / 0.25);
		position: absolute;
		inset-block-start: 50%;
		inset-inline-start: -0.75rem;
		transform: translateY(-50%);
	}
}
.navbar-menu .has-sub {
	justify-content: space-between;
	gap: 0.25rem;
}
@media (min-width: 992px) {
	.navbar-menu .has-sub {
		gap: 0.5rem;
	}
}
.navbar-menu .has-sub::after {
	content: "\f282";
	display: inline-block;
	font-family: bootstrap-icons !important;
	font-size: 1rem;
	font-style: normal;
	font-weight: 400 !important;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	vertical-align: -0.125em;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	padding-top: 0.313rem;
}
.navbar-menu .has-sub.clear-content::after {
	display: none;
}
.menu-open .navbar-menu {
	z-index: 1;
	visibility: visible;
	opacity: 1;
	pointer-events: unset;
	inset-block-start: 100%;
}
@media (min-width: 992px) {
	.menu-item {
		position: relative;
		isolation: isolate;
	}
}
.menu-item + .menu-item .menu-nav-link {
	border-top: 1px solid #2a2a2b;
}
@media (min-width: 992px) {
	.menu-item + .menu-item .menu-nav-link {
		border-top: none;
	}
}
@media (min-width: 992px) {
	.menu-item:hover .menu-dropdown {
		inset-block-start: 100%;
		pointer-events: unset;
		visibility: visible;
		opacity: 1;
	}
}

@media (min-width: 992px) {
	.menu-item:last-child .menu-dropdown {
		inset-inline-start: auto;
		inset-inline-end: 0;
	}
}
.menu-nav-link {
	display: flex;
	align-items: center;
	padding: 0.5rem 1rem;
	position: relative;
	isolation: isolate;
	font-size: 0.875rem;
	color: #fff;
	text-decoration: none;
	font-family: "Sofia Pro";
}
@media (min-width: 992px) {
	.menu-nav-link {
		padding: 1.75rem 2rem;
		font-size: 1.5rem;
	}
}
.menu-nav-link.active {
	color: #fff;
}
.menu-dropdown {
	background-color: #000;

	transition: height 0.3s ease-in-out;
	overflow: hidden;
}
@media (min-width: 992px) {
	.menu-dropdown {
		min-width: 180px;
		position: absolute;
		inset-block-start: calc(100% + 0.5rem);
		inset-inline-end: 0;
		/* background-color: hsl(var(--neutral-99));
    box-shadow: 0 0.5rem 1rem hsl(var(--shadow) / 0.25); */
		box-shadow: 0px 8px 16px rgb(255 255 255 / 20%);
		border-radius: 0.25rem;
		pointer-events: none;
		visibility: hidden;
		opacity: 0;
		transition: all 0.3s ease-in-out;
	}
}
@media (min-width: 1200px) {
	.menu-dropdown {
		inset-inline-start: 0;
		min-width: 200px;
	}
}
.menu-dropdown {
	display: none;
}
.menu-dropdown.menu-dropdown-show {
	display: block;
}
@media (min-width: 992px) {
	.menu-dropdown {
		display: block;
	}
}
.menu-dropdown-link {
	display: flex;
	align-items: center;
	padding: 0.5rem 1rem 0.5rem 2rem;
	position: relative;
	isolation: isolate;
	font-size: 0.875rem;
	color: #fff;
	text-decoration: none;
	transition: all 0.3s ease-in-out;
}
@media (min-width: 992px) {
	.menu-dropdown-link {
		padding: 0.75rem 1rem;
		font-size: 0.875rem;
		color: #fff;
	}
	.menu-dropdown-link:hover {
		background-color: hsl(var(--neutral-95));
		color: #fff;
	}
}
.menu-dropdown-list + .menu-dropdown-list .menu-dropdown-link {
	border-top: 1px solid #2a2a2b;
}
.header-btn {
	text-transform: uppercase;
	--bs-btn-font-size: 1.375rem;
	--bs-btn-padding-x: 2rem;
}

.menu-toggle-btn {
	width: 2.5rem;
	height: 2.5rem;
	padding: 0;
	position: relative;
	transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
}
.menu-toggle-btn span {
	display: block;
	position: absolute;
	height: 0.25rem;
	width: 100%;
	background: #ffffff;
	border-radius: 0.563rem;
	opacity: 1;
	left: 0;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: 0.25s ease-in-out;
	-moz-transition: 0.25s ease-in-out;
	-o-transition: 0.25s ease-in-out;
	transition: 0.25s ease-in-out;
	-webkit-border-radius: 0.563rem;
	-moz-border-radius: 0.563rem;
	-ms-border-radius: 0.563rem;
	-o-border-radius: 0.563rem;
}

.menu-toggle-btn span:nth-child(1) {
	top: 0.65rem;
	width: 75%;
}

.menu-toggle-btn span:nth-child(2),
.menu-toggle-btn span:nth-child(3) {
	top: 1.15rem;
}

.menu-toggle-btn span:nth-child(4) {
	top: 1.65rem;
	width: 50%;
}

.navbar-menu.show {
	z-index: 1;
	visibility: visible;
	opacity: 1;
	pointer-events: unset;
	inset-block-start: 100%;
}

@media (max-width: 767.98px) {
	.navbar-brand img {
		width: auto;
		height: 1.625rem;
	}
}

/* Header CSS Start */

/* Video Section Start */
.video-section {
	padding-top: 18.75rem;
	padding-bottom: 23.75rem;
}
.video-section::after {
	content: "";
	background-image: url(../../Assets/Images/video-bg.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: bottom center;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	bottom: 0;
}
.video-wrap {
	z-index: -100;
}
.video-wrap::before {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: #000;
	top: 0;
	left: 0;
}
.custom-video {
	position: absolute;
	top: 0;
	left: 0;
	object-fit: cover;
	width: 100%;
	height: 100%;
	opacity: 0.4;
}
.playIcon {
	width: 6.25rem;
	height: 6.25rem;
}
@media (min-width: 1200px) {
	.text-h1 {
		font-size: 4rem;
	}
}
@media (max-width: 575.98px) {
	.playIcon {
		width: 4rem;
		height: 4rem;
	}
	.video-section {
		padding-top: 14rem;
		padding-bottom: 14rem;
	}
}
/* Video Section End */

/* Our Products Section Start */
.product-section {
	margin-top: -5rem;
}
.product-card {
	cursor: pointer;
}
.product-card::before {
	content: "";
	display: block;
	padding-top: 71.37%;
}
.btn-lg.page-btn-lm {
	--bs-btn-padding-y: 0.563rem;
	--bs-btn-padding-x: 2rem;
	text-transform: uppercase;
}
.prod-card-content {
	transform: translateY(66%);
	-webkit-transform: translateY(66%);
	-moz-transform: translateY(66%);
	-ms-transform: translateY(66%);
	-o-transform: translateY(66%);
	transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
}
.prod-card-content p,
.prod-card-content a.btn {
	opacity: 0;
	transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
}
.product-card img {
	opacity: 1;
	transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
}
.product-card:hover .prod-card-content {
	transform: translateY(0%);
	-webkit-transform: translateY(0%);
	-moz-transform: translateY(0%);
	-ms-transform: translateY(0%);
	-o-transform: translateY(0%);
}
.product-card:hover .prod-card-content p,
.product-card:hover .prod-card-content a.btn {
	opacity: 1;
}
.product-card:hover img {
	opacity: 0.6;
}

@media (max-width: 767.98px) {
	.product-card .fs-1 {
		font-size: 1.25rem !important;
	}
	.prod-card-content p,
	.prod-card-content a.btn {
		font-size: 0.75rem !important;
	}
	.prod-card-content p {
		margin-bottom: 0.5rem;
	}
	.prod-card-content a.btn {
		--bs-btn-padding-y: 0.5rem;
		--bs-btn-padding-x: 1.75rem;
	}
	.btn-lg.page-btn-lm {
		--bs-btn-padding-y: 0.5rem;
		--bs-btn-padding-x: 1.5rem;
		--bs-btn-font-size: 1rem;
	}
}
@media (max-width: 575.98px) {
	.product-section {
		margin-top: -3rem;
	}
}
/* Our Products Section End */

/* Solution Display Section Start */
.sd-image {
	width: 41.875rem;
	max-width: 100%;
}
.sd-image::before {
	content: "";
	display: block;
	padding-top: 117.91%;
}

.section-text {
	font-size: 1.25rem;
	color: #ffffff;
}
@media (max-width: 767.98px) {
	.section-text {
		font-size: 0.875rem;
	}
	.section-text .mb-5 {
		margin-bottom: 2rem !important;
	}
}
/* Solution Display Section End */

/* Portfolio Section Start */
.portfolio-section {
	padding: 5rem 0;
}
.op_image::before {
	content: "";
	display: block;
}
.op_image_1::before {
	padding-top: 150%;
}
.op_image_2 {
	padding-top: 130%;
}
.op_image_3 {
	padding-top: 62%;
}
@media (max-width: 575.98px) {
	.portfolio-section {
		padding: 3rem 0;
	}
}
/* Portfolio Section End */

/* Newsletter Section Start */
.newsletter-section {
	background-image: linear-gradient(90deg, rgb(248, 93, 148) 0%, rgb(254, 19, 94) 100%);
	background-image: -moz-linear-gradient(90deg, rgb(248, 93, 148) 0%, rgb(254, 19, 94) 100%);
	background-image: -webkit-linear-gradient(90deg, rgb(248, 93, 148) 0%, rgb(254, 19, 94) 100%);
	background-image: -ms-linear-gradient(90deg, rgb(248, 93, 148) 0%, rgb(254, 19, 94) 100%);
	padding: 8.125rem 0;
}
.newsletter-section.bg-overlay-common::before {
	z-index: 1;
	opacity: 0.1;
	background-position: 100% 50%;
}
.newsletter-from .form-control-lg {
	min-height: calc(2.5rem + 2.687rem + calc(0.063rem * 2));
	border-radius: 0.313rem;
	-webkit-border-radius: 0.313rem;
	-moz-border-radius: 0.313rem;
	-ms-border-radius: 0.313rem;
	-o-border-radius: 0.313rem;
	background-color: #ffffff;
	border: var(--bs-border-width) solid var(--bs-border-color);
	color: #000;
	padding: 0.5rem 2rem;
}

.newsletter-from .form-control-lg::-moz-placeholder {
	font-size: 1.25rem;
	color: #666666;
	opacity: 1;
}
.newsletter-from .form-control-lg::placeholder {
	font-size: 1.25rem;
	color: #666666;
	opacity: 1;
}

.btn-light.sub_btn {
	text-transform: uppercase;
	--bs-btn-color: #fe135e;
	--bs-btn-bg: #fff;
	--bs-btn-hover-bg: #d3d4d5;
	--bs-btn-hover-color: #fff;
	--bs-btn-hover-bg: #fa4784;
	--bs-btn-hover-border-color: #fff;
	--bs-btn-font-size: 1.5rem;
	font-weight: 600;
}
@media (max-width: 767.98px) {
	.newsletter-section {
		padding: 3rem 0;
	}
	.newsletter-from .form-control-lg {
		min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
		padding: 0.5rem 1rem;
		font-size: 1.25rem;
	}
	.newsletter-section .fs-4 {
		font-size: 1rem !important;
	}
	.btn-light.sub_btn {
		--bs-btn-font-size: 1rem;
	}
}
/* Newsletter Section End */

/* Footer Start */
.footer {
	padding: 6.25rem 0;
}
.navbar-brand-footer img {
	height: 2.375rem;
}
.ftr-link {
	font-size: 1.5rem;
	text-decoration: none;
	display: inline-block;
	color: #fff;
	margin: 0 1rem;
}
.social-btn {
	width: 3.75rem;
	height: 3.75rem;
	line-height: 3.75rem;
	padding: 0;
	font-size: 1.5rem;
	border: 0;
	margin: 0 0.5rem;
	color: #ffffff !important;
}
.footer::after {
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	height: 0.5rem;
	width: 100%;
	background-color: #fe135e;
}
@media (max-width: 767.98px) {
	.footer {
		padding: 3rem 0;
	}
	.navbar-brand-footer img {
		height: 1.625rem;
	}
	.ftr-link {
		font-size: 1rem;
		margin-bottom: 0.5rem;
	}
	.social-btn {
		width: 2.75rem;
		height: 2.75rem;
		line-height: 2.75rem;
		padding: 0;
		font-size: 1rem;
	}
	.footer .fs-5 {
		font-size: 0.875rem !important;
	}
}
/* Footer End */

/* Login Page CSS Start */
/* .login-bg{
  background-image: url('../images/thumnail.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.login-bg::after{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.6);
}
.login-card{
  position: relative;
  isolation: isolate;
  background: rgba(0, 0, 0, 0.8);
  border-color: #434647;
}
.login-card.animated-border-btn{
  --border-width: 0.063rem;
} */
/* Login Page CSS End */

/* Other page CSS Start */
.subpageContainer {
	min-height: 100vh;
	position: relative;
	background-color: #ffffff;
	padding-bottom: 2.5rem;
	overflow: hidden;
}
.subpageContainer::before {
	content: "";
	width: 150%;
	height: 150%;
	background: linear-gradient(90deg, #df2caa 0%, #fe5e3e 100%);
	border-radius: 100%;
	top: 50%;
	left: -25%;
	right: 0;
	position: fixed;
}
.cursor-pointer {
	cursor: pointer;
}
/* Page Titile */
.fs-40 {
	font-size: 2.5rem;
}
.text-primary-gradient {
	background: linear-gradient(90deg, #df2caa 0%, #fe5e3e 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
}
.bg-primary-gradiant,
.rc-virtual-list-scrollbar-thumb {
	background: linear-gradient(90deg, #df2caa 0%, #fe5e3e 100%) !important;
}
.backBtnLink svg {
	margin-right: 0.75rem;
}
.pageTitleRow {
	padding: 3.125rem 0;
}
.mb-30 {
	margin-bottom: 1.875rem;
}
.btn-light.pc__btn {
	--bs-btn-bg: #ffffff;
	--bs-btn-border-color: #ffffff;
}
@media (min-width: 576px) {
	.page-gradiant-card .card-body {
		padding: 1.875rem 2.5rem;
	}
}

.myProfileCard .card-body {
	padding: 3.125rem 1.5rem;
}
.imageBox {
	width: 12.5rem;
	height: 12.5rem;
	margin-bottom: 2.5rem;
	background: linear-gradient(90deg, #df2caa 0%, #fe5e3e 100%);
}
.profileUploadBtn {
	right: 0;
	bottom: 1.5rem;
}
.profileUploadBtn svg {
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 100%;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	-ms-border-radius: 100%;
	-o-border-radius: 100%;
}
.ant-tabs-nav {
	margin-bottom: 1.25rem !important;
}
.ant-tabs-nav::before {
	display: none;
}
.ant-tabs-nav-list {
	background-color: #f2f2f2;
	border-radius: 0.313rem;
	-webkit-border-radius: 0.313rem;
	-moz-border-radius: 0.313rem;
	-ms-border-radius: 0.313rem;
	-o-border-radius: 0.313rem;
}
.ant-tabs .ant-tabs-tab {
	background-color: #f2f2f2;
	margin: 0 !important;
	padding: 1rem 2.5rem;
	font-size: 1.25rem;
	border-radius: 0.313rem;
	-webkit-border-radius: 0.313rem;
	-moz-border-radius: 0.313rem;
	-ms-border-radius: 0.313rem;
	-o-border-radius: 0.313rem;
}

.ant-tabs .ant-tabs-tab:hover {
	color: #000000;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active {
	background-color: #000000;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: #ffffff;
}
.rc-virtual-list-scrollbar-horizontal,
.ant-tabs-ink-bar {
	display: none;
}
.ci__text.h-0 {
	height: 0;
}
.ci__text span {
	position: relative;
	bottom: -1rem;
	margin-top: 0;
}
/* Other page CSS End */

/* Modal CSS START */
.ant-modal {
	width: 46.25rem !important;
}
.ant-modal .ant-modal-header {
	text-align: center;
	margin-bottom: 1rem;
}
.ant-modal .ant-modal-title {
	font-size: 1.875rem;
	font-weight: 700 !important;
}
.form-control.modal-input {
	background-color: #f2f2f2;
	border-color: #f2f2f2;
	color: #000;
	font-size: 1rem;
	border-radius: 0.313rem;
	-webkit-border-radius: 0.313rem;
	-moz-border-radius: 0.313rem;
	-ms-border-radius: 0.313rem;
	-o-border-radius: 0.313rem;
}
.form-control.modal-input {

}
.form-control.modal-input::-moz-placeholder {
	color: #8e8e8e;
	opacity: 1;
	font-size: 1.25rem;
}
.form-control.modal-input::placeholder {
	color: #8e8e8e;
	opacity: 1;
	font-size: 1.25rem;
}
.pc__input {
	width: 18.75rem;
	margin: 0 auto;
}

.modalRadioBtn .btn-outline-primary {
	--bs-btn-color: #000000;
  --bs-btn-border-color: #000000;
  --bs-btn-font-size: 1rem;
  margin: 0 0.25rem;
  --bs-btn-padding-x: 0.875rem;
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-active-bg: #ffffff;
  --bs-btn-active-color: #DF2CAA;
  --bs-btn-active-border-color: #DF2CAA;
}

.buyButton {
	width: 15rem;
	max-width: 100%;
}
.ant-modal-footer {
	text-align: center !important;
}
.ant-modal .ant-modal-content{
	padding-top: 3rem;
	padding-bottom: 3rem;
  }

.creditModal.ant-modal .ant-modal-content {
	padding: 3.125rem 0rem;
}
/* Modal CSS END */

.blackText {
	color: black;
}


.tableCard{
	min-height: 30rem;
	overflow: hidden;
}
@media (min-width: 768px) {
	.ant-modal .ant-modal-content{
		padding: 3.125rem 5.625rem;
	  }
	.modalRadioBtn .btn-outline-primary{
		--bs-btn-font-size: 1.25rem;
		margin: 0 0.938rem;
	  --bs-btn-padding-x: 2rem;
	  --bs-btn-padding-y: 0.75rem;
	  }
}
@media (max-width: 767.98px) {
	.fs-40 {
		font-size: 1.5rem;
	}
}